
/**
 * Utility classes for setting the background color opacity.
 *
 * The following classes are available:
 * - `.bg-opacity-0`: Sets the background color to fully transparent (0% opacity).
 * - `.bg-opacity-25`: Sets the background color to 25% opacity.
 * - `.bg-opacity-50`: Sets the background color to 50% opacity.
 * - `.bg-opacity-75`: Sets the background color to 75% opacity.
 * - `.bg-opacity-100`: Sets the background color to fully opaque (100% opacity).
 */
.bg-opacity-0 { background-color: rgba(0, 0, 0, 0); }
.bg-opacity-25 { background-color: rgba(0, 0, 0, 0.25); }
.bg-opacity-50 { background-color: rgba(0, 0, 0, 0.5); }
.bg-opacity-75 { background-color: rgba(0, 0, 0, 0.75); }
.bg-opacity-100 { background-color: rgba(0, 0, 0, 1); }
