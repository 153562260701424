
/**
 * Utility classes for applying box shadow effects to elements.
 *
 * - `.shadow-sm`: Applies a small box shadow.
 * - `.shadow`: Applies a medium box shadow.
 * - `.shadow-md`: Applies a medium-large box shadow.
 * - `.shadow-lg`: Applies a large box shadow.
 * - `.shadow-xl`: Applies an extra-large box shadow.
 * - `.shadow-none`: Removes any box shadow.
 */
.shadow-sm { box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
.shadow { box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); }
.shadow-md { box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); }
.shadow-lg { box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1); }
.shadow-xl { box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15); }
.shadow-none { box-shadow: none; }
