@use '../variables' as vars;
/**
 * Utility CSS classes for setting the maximum width of an element based on common screen sizes.
 * The max-width values are defined in the `$bp-*` Sass variables.
 */
.max-w-screen-xs {	max-width: vars.$bp-xs; }
.max-w-screen-sm {	max-width: vars.$bp-sm; }
.max-w-screen-md {	max-width: vars.$bp-md; }
.max-w-screen-ld {	max-width: vars.$bp-ld; }
.max-w-screen-xl {	max-width: vars.$bp-xl; }

$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$width-values: (
  "unset": unset,
  "100": 100%,
);

@each $key, $value in $width-values {
  .max-width-#{$key} {
    max-width: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $width-values {
      .#{$breakpoint}\:min-width-#{$key} {
        max-width: #{$value};
      }
    }
  }
}
