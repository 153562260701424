@use '../variables' as vars;

$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$text-transform-classes: (
  "uppercase": uppercase,
  "lowercase": lowercase,
  "capitalize": capitalize,
  "normal-case": none
);

@each $name, $value in $text-transform-classes {
  .#{$name} {
    text-transform: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $name, $value in $text-transform-classes {
      .#{$breakpoint}\:#{$name} {
        text-transform: #{$value};
      }
    }
  }
}

