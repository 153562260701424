
// SCSS Variables with defaults
$base-font-size: 16 !default;

$space-xs: 0.125rem !default;
$space-sm: 0.25rem !default;
$space-md: 0.375rem !default;
$space-lg: 0.5rem !default;
$space-xl: 0.75rem !default;
$space-2xl: 1rem !default;
$space-3xl: 1.25rem !default;
$space-4xl: 1.5rem !default;

$font-size-xs: 0.75rem !default;
$font-size-sm: 0.875rem !default;
$font-size-base: 1rem !default;
$font-size-lg: 1.125rem !default;
$font-size-xl: 1.25rem !default;
$font-size-2xl: 1.5rem !default;
$font-size-3xl: 1.875rem !default;
$font-size-4xl: 2.25rem !default;
$font-size-5xl: 3rem !default;

$border-radius-sm: 0.125rem !default;
$border-radius-base: 0.25rem !default;
$border-radius-md: 0.375rem !default;
$border-radius-lg: 0.5rem !default;

$bp-xs: 536px !default;
$bp-sm: 768px !default;
$bp-md: 992px !default;
$bp-ld: 1200px !default;
$bp-xl: 1500px !default;
