
/**
 * Utility classes for setting the background-size property.
 *
 * - `.bg-auto`: Sets the background-size to `auto`, which will size the background image to its original size.
 * - `.bg-cover`: Sets the background-size to `cover`, which will scale the image to fill the entire container while maintaining aspect ratio.
 * - `.bg-contain`: Sets the background-size to `contain`, which will scale the image to fit within the container while maintaining aspect ratio.
 */
.bg-auto { background-size: auto; }
.bg-cover { background-size: cover; }
.bg-contain { background-size: contain; }
.bg-size-100 { background-size: 100% 100%; }
