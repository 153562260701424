@use '../variables' as vars;
/**
 * Utility classes for controlling the `justify-content` property of flex containers.
 *
 * The following classes are available:
 * - `.justify-start`: Aligns flex items to the start of the flex container.
 * - `.justify-end`: Aligns flex items to the end of the flex container.
 * - `.justify-center`: Aligns flex items to the center of the flex container.
 * - `.justify-between`: Distributes flex items evenly along the main axis, with the first item at the start and the last item at the end.
 * - `.justify-around`: Distributes flex items evenly along the main axis, with equal space around each item.
 * - `.justify-evenly`: Distributes flex items evenly along the main axis, with equal space between and around the items.
 *
 * These classes can also be used with breakpoint-specific modifiers, e.g. `.xs:justify-center` or `.md:justify-between`.
 */
 $breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$justify-classes: (
  "start": flex-start,
  "end": flex-end,
  "center": center,
  "between": space-between,
  "around": space-around,
  "evenly": space-evenly
);

@each $name, $value in $justify-classes {
  .justify-#{$name} {
    justify-content: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $name, $value in $justify-classes {
      .#{$breakpoint}\:justify-#{$name} {
        justify-content: #{$value};
      }
    }
  }
}

