
/**
 * Utility classes for controlling the background repeat behavior of an element.
 *
 * - `.bg-repeat`: Repeats the background image both horizontally and vertically.
 * - `.bg-no-repeat`: Does not repeat the background image.
 * - `.bg-repeat-x`: Repeats the background image horizontally.
 * - `.bg-repeat-y`: Repeats the background image vertically.
 * - `.bg-repeat-round`: Repeats the background image and scales it to fill the available space.
 * - `.bg-repeat-space`: Repeats the background image with some space between the repeats.
 */
.bg-repeat { background-repeat: repeat; }
.bg-no-repeat { background-repeat: no-repeat; }
.bg-repeat-x { background-repeat: repeat-x; }
.bg-repeat-y { background-repeat: repeat-y; }
.bg-repeat-round { background-repeat: round; }
.bg-repeat-space { background-repeat: space; }
