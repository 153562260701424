
/**
 * Positions an element absolutely centered within its parent container.
 *
 * This mixin can be used to center an element both horizontally and vertically
 * within its parent container, assuming the parent has `position: relative`.
 * The element will be translated by 50% of its own width and height to the
 * top-left corner of the parent container.
 */
.absolute-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
