
/**
 * CSS utility classes for controlling the user-select property.
 *
 * - `.select-none`: Disables text selection for the element.
 * - `.select-text`: Allows text selection for the element.
 * - `.select-all`: Allows selection of the entire element.
 * - `.select-auto`: Uses the default user-select behavior for the element.
 */
.select-none { user-select: none; }
.select-text { user-select: text; }
.select-all { user-select: all; }
.select-auto { user-select: auto; }
