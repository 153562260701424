
/**
 * Utility classes for setting the opacity of an element.
 *
 * - `.opacity-0`: Sets the opacity of an element to 0, making it fully transparent.
 * - `.opacity-25`: Sets the opacity of an element to 0.25, making it 25% visible.
 * - `.opacity-50`: Sets the opacity of an element to 0.5, making it 50% visible.
 * - `.opacity-75`: Sets the opacity of an element to 0.75, making it 75% visible.
 * - `.opacity-100`: Sets the opacity of an element to 1, making it fully visible.
 */
.opacity-0 { opacity: 0; }
.opacity-25 { opacity: 0.25; }
.opacity-50 { opacity: 0.5; }
.opacity-75 { opacity: 0.75; }
.opacity-100 { opacity: 1; }
