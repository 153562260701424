
/**
 * Utility classes for rotating elements by a specified angle.
 *
 * - `.rotate-0`: Rotates the element 0 degrees.
 * - `.rotate-45`: Rotates the element 45 degrees.
 * - `.rotate-90`: Rotates the element 90 degrees.
 * - `.rotate-180`: Rotates the element 180 degrees.
 * - `.-rotate-45`: Rotates the element -45 degrees.
 * - `.-rotate-90`: Rotates the element -90 degrees.
 * - `.-rotate-180`: Rotates the element -180 degrees.
 */
 $rotate-classes: (
  "rotate-0": rotate(0deg),
  "rotate-45": rotate(45deg),
  "rotate-90": rotate(90deg),
  "rotate-180": rotate(180deg),
  "-rotate-45": rotate(-45deg),
  "-rotate-90": rotate(-90deg),
  "-rotate-180": rotate(-180deg)
);
