@use '../variables' as vars;

$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$position-classes: (
  "left": left,
  "right": right,
  "top": top,
  "bottom": bottom
);

@each $key, $value in $position-classes {
  .#{$key}-0 {
    #{$value}: 0%;
  }
  .#{$key}-100 {
    #{$value}: 100%;
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $position-classes {
      .#{$breakpoint}\:#{$key}-0 {
        #{$value}: 0%;
      }
      .#{$breakpoint}\:#{$key}-100 {
        #{$value}: 100%;
      }
    }
  }
}

