
/**
 * Utility classes for setting the box-sizing property.
 *
 * - `.border-box`: Sets the box-sizing property to `border-box`, which includes the padding and border in the element's total width and height.
 * - `.content-box`: Sets the box-sizing property to `content-box`, which excludes the padding and border from the element's total width and height.
 */
.border-box {
  box-sizing: border-box;
}

.content-box {
  box-sizing: content-box;
}
