@use '../variables' as vars;
/**
 * Utility classes for setting the minimum width of an element.
 *
 * The `min-width-*` classes set the `min-width` property of an element to a specific value.
 * The `*:min-width-*` classes set the `min-width` property of an element to a specific value at a given breakpoint.
 *
 * The available values for `*` are:
 * - `unset`: Sets the `min-width` property to `unset`.
 *
 * The available breakpoints are:
 * - `xs`: Extra small screen size.
 * - `sm`: Small screen size.
 * - `md`: Medium screen size.
 * - `ld`: Large screen size.
 * - `xl`: Extra large screen size.
 */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$width-values: (
  "unset": unset,
  "100": 100%,
);

@each $key, $value in $width-values {
  .min-width-#{$key} {
    min-width: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $width-values {
      .#{$breakpoint}\:min-width-#{$key} {
        min-width: #{$value};
      }
    }
  }
}
