
/**
 * Utility classes for scaling elements using CSS transforms.
 * The `--transform-value` CSS variable is set to the appropriate scale factor.
 */
 $scale-classes: (
  "scale-50": scale(0.5),
  "scale-75": scale(0.75),
  "scale-90": scale(0.9),
  "scale-100": scale(1),
  "scale-105": scale(1.05),
  "scale-110": scale(1.1),
  "scale-125": scale(1.25),
  "scale-150": scale(1.5)
);

