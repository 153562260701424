@use '../variables' as vars;
/**
 * Utility classes for controlling the display property of elements.
 *
 * The `$display-classes` map defines a set of display-related utility classes that can be applied to elements. These classes
 * set the `display` property of the element to one of the following values: `block`, `inline-block`, `inline`, `flex`,
 * `inline-flex`, `grid`, `inline-grid`, or `none` (hidden).
 *
 * The utility classes are generated using a Sass `@each` loop that iterates over the `$display-classes` map.
 *
 * Additionally, responsive versions of these utility classes are generated using another `@each` loop that iterates over the
 * `$breakpoints` map. These responsive classes follow the pattern `{breakpoint}:{display-class}`, where `{breakpoint}` is
 * one of the keys in the `$breakpoints` map (e.g. `sm:block`, `md:flex`).
 */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$display-classes: (
  "block": block,
  "inline-block": inline-block,
  "inline": inline,
  "flex": flex,
  "inline-flex": inline-flex,
  "grid": grid,
  "inline-grid": inline-grid,
  "hidden": none
);

@each $key, $value in $display-classes {
  .#{$key} {
    display: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $display-classes {
      .#{$breakpoint}\:#{$key} {
        display: #{$value};
      }
    }
  }
}

