@use 'scale' as scale;
@use 'rotate' as rotate;
@use 'translate' as translate;
// This Sass code block generates CSS classes for rotating elements. It uses a `@each` loop to iterate over a map of rotation class names and values, and applies the appropriate `--tw-rotate` CSS variable to each class. The `transform` property is then set to apply the rotation along with any other transform properties that may be defined.
@each $name, $value in rotate.$rotate-classes {
  .#{$name} {
    --tw-rotate: #{$value};
    transform: var(--tw-rotate) var(--tw-scale, scale(1)) var(--tw-translate-x, translateX(0)) var(--tw-translate-y, translateY(0));
  }
}

// This code block generates CSS classes for scaling elements along the X and Y axes. It uses a Sass `@each` loop to iterate over a map of scale class names and values, and applies the appropriate `--tw-scale` CSS variable to each class. The `transform` property is then set to apply the scale along with any other transform properties that may be defined.
@each $name, $value in scale.$scale-classes {
  .#{$name} {
    --tw-scale: #{$value};
    transform: var(--tw-rotate, rotate(0)) var(--tw-scale) var(--tw-translate-x, translateX(0)) var(--tw-translate-y, translateY(0));
  }
}

// This code block generates CSS classes for translating elements along the X and Y axes. It uses a Sass `@each` loop to iterate over a map of translation class names and values, and applies the appropriate `--tw-translate-x` or `--tw-translate-y` CSS variable to each class. The `transform` property is then set to apply the translation along with any other transform properties that may be defined.
@each $name, $value in translate.$translate-classes {
  .#{$name} {
    @if str-index($name, "translate-x") {
      --tw-translate-x: #{$value};
    } @else if str-index($name, "translate-y") {
      --tw-translate-y: #{$value};
    }
    transform: var(--tw-rotate, rotate(0)) var(--tw-scale, scale(1)) var(--tw-translate-x, translateX(0)) var(--tw-translate-y, translateY(0));
  }
}
