
/**
 * Defines a set of CSS classes that set the number of rows in a grid layout.
 * The classes are named `.grid-rows-{n}` where `{n}` is the number of rows,
 * and they set the `grid-template-rows` property to `repeat({n}, 1fr)`.
 * This allows easily creating grids with a variable number of rows.
 */
$rows-range: 1, 2, 3, 4, 5, 6;

@each $i in $rows-range {
  .grid-rows-#{$i} {
    grid-template-rows: repeat(#{$i}, 1fr);
  }
}
