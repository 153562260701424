@use '../variables' as vars;
/**
 * Generates CSS classes for grid layouts with a specified number of columns.
 *
 * The generated classes follow the format `grid-cols-{n}`, where `{n}` is the
 * number of columns, ranging from 1 to 12.
 *
 * These classes can be used to set the `grid-template-columns` property on a
 * grid container element, creating a grid layout with the specified number of
 * equal-width columns.
 */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$cols-range: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;

@each $i in $cols-range {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }
}

@each $bp-name, $bp-size in $breakpoints {
  @media (max-width: #{$bp-size}) {
    @each $n in $cols-range {
      .#{$bp-name}\:grid-cols-#{$n} {
        grid-template-columns: repeat(#{$n}, 1fr);
      }
    }
  }
}
