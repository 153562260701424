@use '../variables' as vars;

 /**
  * Defines utility classes for text alignment at different breakpoints.
  *
  * The `$text-align-classes` map defines the available text alignment classes,
  * which are then generated using the `@each` loop.
  *
  * The breakpoint-specific text alignment classes are generated using another
  * `@each` loop that iterates over the `$breakpoints` map and applies the
  * text alignment classes for each breakpoint.
  */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$text-align-classes: (
  "left": left,
  "center": center,
  "right": right,
  "justify": justify,
);

@each $name, $value in $text-align-classes {
  .text-#{$name} {
    text-align: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $name, $value in $text-align-classes {
      .#{$breakpoint}\:text-#{$name} {
        text-align: #{$value};
      }
    }
  }
}
