
/**
 * Utility classes for setting the border width.
 *
 * - `.border`: Sets the border width to 1px.
 * - `.border-0`: Sets the border width to 0 (no border).
 * - `.border-2`: Sets the border width to 2px.
 * - `.border-4`: Sets the border width to 4px.
 * - `.border-8`: Sets the border width to 8px.
 */
.border { border: solid; border-width: 1px; border-color: inherit; }
.border-0 { border: solid; border-width: 0; border-color: inherit; }
.border-2 { border: solid; border-width: 2px; border-color: inherit; }
.border-4 { border: solid; border-width: 4px; border-color: inherit; }
.border-8 { border: solid; border-width: 8px; border-color: inherit; }
