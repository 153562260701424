
/**
 * Utility classes for setting the background position of an element.
 *
 * - `.bg-bottom`: Sets the background position to the bottom of the element.
 * - `.bg-center`: Sets the background position to the center of the element.
 * - `.bg-left`: Sets the background position to the left of the element.
 * - `.bg-left-bottom`: Sets the background position to the bottom left of the element.
 * - `.bg-left-top`: Sets the background position to the top left of the element.
 * - `.bg-right`: Sets the background position to the right of the element.
 * - `.bg-right-bottom`: Sets the background position to the bottom right of the element.
 * - `.bg-right-top`: Sets the background position to the top right of the element.
 * - `.bg-top`: Sets the background position to the top of the element.
 */
.bg-bottom { background-position: bottom; }
.bg-center { background-position: center; }
.bg-left { background-position: left; }
.bg-left-bottom { background-position: left bottom; }
.bg-left-top { background-position: left top; }
.bg-right { background-position: right; }
.bg-right-bottom { background-position: right bottom; }
.bg-right-top { background-position: right top; }
.bg-top { background-position: top; }
