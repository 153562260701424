@use '../variables' as vars;
/**
 * Utility classes for setting the width and height of elements.
 *
 * The `$width-values` and `$height-values` maps define a set of predefined
 * width and height values that can be used to set the width and height of
 * elements using utility classes.
 *
 * The width utility classes are prefixed with `w-` and the height utility
 * classes are prefixed with `h-`. For example, `w-50` sets the width to 50%
 * and `h-75` sets the height to 75%.
 *
 * The utility classes can also be used with responsive breakpoints. For
 * example, `sm:w-50` sets the width to 50% on small screens and below.
 */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$width-values: (
  "1\\/3": 33.3333%,
  "2\\/3": 66.6667%,
  "25": 25%,
  "50": 50%,
  "75": 75%,
  "100": 100%,
  "auto": auto,
  "unset": unset
);

$height-values: (
  "25": 25%,
  "50": 50%,
  "75": 75%,
  "100": 100%,
  "auto": auto,
  "unset": unset
);

@each $key, $value in $width-values {
  .w-#{$key} {
    width: #{$value};
  }
}

@each $key, $value in $height-values {
  .h-#{$key} {
    height: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $width-values {
      .#{$breakpoint}\:w-#{$key} {
        width: #{$value};
      }
    }
    @each $key, $value in $height-values {
      .#{$breakpoint}\:h-#{$key} {
        height: #{$value};
      }
    }
  }
}
