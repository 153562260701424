
/**
 * Utility classes for setting the transform-origin property on elements.
 * The transform-origin property specifies the origin point of a transform.
 * This allows you to control the position of the transformed element.
 */
.origin-center { transform-origin: center; }
.origin-top { transform-origin: top; }
.origin-top-right { transform-origin: top right; }
.origin-right { transform-origin: right; }
.origin-bottom-right { transform-origin: bottom right; }
.origin-bottom { transform-origin: bottom; }
.origin-bottom-left { transform-origin: bottom left; }
.origin-left { transform-origin: left; }
.origin-top-left { transform-origin: top left; }
