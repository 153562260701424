@use '../variables' as vars;

/**
 * Provides a utility class to center an element horizontally within its parent container.
 * The `.m-auto` class sets the left and right margins to `auto`, which centers the element.
 * This can be used at the global level or scoped to specific breakpoints using the responsive utility classes.
 */
 $breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

.m-auto {
  margin: 0 auto;
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    .#{$breakpoint}\:m-auto {
      margin: 0 auto;
    }
  }
}
