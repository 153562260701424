/**
 * Utility classes for applying different levels of blur effect to elements.
 *
 * - `.blur-none`: Removes any blur effect.
 * - `.blur-sm`: Applies a small blur effect with 4px radius.
 * - `.blur`: Applies a moderate blur effect with 8px radius.
 * - `.blur-md`: Applies a medium blur effect with 12px radius.
 * - `.blur-lg`: Applies a large blur effect with 16px radius.
 * - `.blur-xl`: Applies an extra-large blur effect with 24px radius.
 */
.blur-none {	filter:  unset; }
.blur-sm {	filter: blur(4px); }
.blur { filter: blur(8px); }
.blur-md {	filter: blur(12px); }
.blur-lg {	filter: blur(16px); }
.blur-xl {	filter: blur(24px); }
