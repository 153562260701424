@use '../variables' as vars;
/**
 * This SCSS file defines a set of utility classes for managing margins in a responsive web application.
 *
 * The file defines a set of breakpoints and a map of margin values that are used to generate the utility classes.
 * The utility classes are generated using Sass loops and include classes for setting margin, margin-top, margin-right, margin-bottom, margin-left, margin-x, and margin-y.
 * The utility classes are also generated for each of the defined breakpoints, allowing for responsive margin styles.
 */
 $breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$margin-values: ();

@for $i from 0 through 16 {
  @if $i % 2 == 0 {
    $margin-values: map-merge($margin-values, ("#{$i}" : #{$i}px));
    @if $i > 0 {
      $margin-values: map-merge($margin-values, ("-#{$i}" : -#{$i}px));
    }
  }
}

@for $i from 20 through 36 {
  @if $i % 4 == 0 {
    $margin-values: map-merge($margin-values, ("#{$i}" : #{$i}px));
    $margin-values: map-merge($margin-values, ("-#{$i}" : -#{$i}px));
  }
}

@for $i from 40 through 100 {
  @if $i % 10 == 0 {
    $margin-values: map-merge($margin-values, ("#{$i}" : #{$i}px));
    $margin-values: map-merge($margin-values, ("-#{$i}" : -#{$i}px));
  }
}

@each $key, $value in $margin-values {
  .m-#{$key} {
    margin: #{$value};
  }
}

@each $key, $value in $margin-values {
  .mt-#{$key} {
    margin-top: #{$value};
  }
  .mr-#{$key} {
    margin-right: #{$value};
  }
  .mb-#{$key} {
    margin-bottom: #{$value};
  }
  .ml-#{$key} {
    margin-left: #{$value};
  }
  .mx-#{$key} {
    margin-left: #{$value};
    margin-right: #{$value};
  }
  .my-#{$key} {
    margin-top: #{$value};
    margin-bottom: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $margin-values {
      .#{$breakpoint}\:m-#{$key} {
        margin: #{$value};
      }
      .#{$breakpoint}\:mt-#{$key} {
        margin-top: #{$value};
      }
      .#{$breakpoint}\:mr-#{$key} {
        margin-right: #{$value};
      }
      .#{$breakpoint}\:mb-#{$key} {
        margin-bottom: #{$value};
      }
      .#{$breakpoint}\:ml-#{$key} {
        margin-left: #{$value};
      }
      .#{$breakpoint}\:mx-#{$key} {
        margin-left: #{$value};
        margin-right: #{$value};
      }
      .#{$breakpoint}\:my-#{$key} {
        margin-top: #{$value};
        margin-bottom: #{$value};
      }
    }
  }
}
