@use '../variables' as vars;

$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$order-classes: (
  "first": -9999,
  "last": 9999,
  "none": 0
);

$order-range: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
@each $i in $order-range {
  .order-#{$i} {
    order: #{$i};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $i in $order-range {
      .#{$breakpoint}\:order-#{$i} {
        order: #{$i};
      }
    }
  }
}

@each $name, $value in $order-classes {
  .order-#{$name} {
    order: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $name, $value in $order-classes {
      .#{$breakpoint}\:order-#{$name} {
        order: #{$value};
      }
    }
  }
}

