@use '../variables' as vars;
/**
 * Defines utility classes for setting the minimum height of elements.
 *
 * The `min-height-{key}` classes set the minimum height of an element to the value specified by the `$height-values` map.
 * The `{breakpoint}\:min-height-{key}` classes set the minimum height of an element to the value specified by the `$height-values` map at the specified breakpoint.
 *
 * The available breakpoints are defined in the `$breakpoints` map.
 */
 $breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$height-values: (
  "unset": unset
);

@each $key, $value in $height-values {
  .min-height-#{$key} {
    min-height: #{$value};
  }
}

@each $breakpoint, $height in $breakpoints {
  @media (max-width: #{$height}) {
    @each $key, $value in $height-values {
      .#{$breakpoint}\:min-height-#{$key} {
        min-height: #{$value};
      }
    }
  }
}
