@mixin icon-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == ada {
  $char: "\E001";
}
  @if $filename == android {
  $char: "\E002";
}
  @if $filename == app-arrow-1 {
  $char: "\E003";
}
  @if $filename == app-arrow-2 {
  $char: "\E004";
}
  @if $filename == app-arrow-3 {
  $char: "\E005";
}
  @if $filename == app {
  $char: "\E006";
}
  @if $filename == apple {
  $char: "\E007";
}
  @if $filename == apply-fat {
  $char: "\E008";
}
  @if $filename == apply-wide {
  $char: "\E009";
}
  @if $filename == apply {
  $char: "\E00A";
}
  @if $filename == arrow {
  $char: "\E00B";
}
  @if $filename == attention {
  $char: "\E00C";
}
  @if $filename == bag-solid {
  $char: "\E00D";
}
  @if $filename == bag {
  $char: "\E00E";
}
  @if $filename == bch {
  $char: "\E00F";
}
  @if $filename == bnb {
  $char: "\E010";
}
  @if $filename == bonuses {
  $char: "\E011";
}
  @if $filename == btc {
  $char: "\E012";
}
  @if $filename == burger {
  $char: "\E013";
}
  @if $filename == car {
  $char: "\E014";
}
  @if $filename == close {
  $char: "\E015";
}
  @if $filename == compress {
  $char: "\E016";
}
  @if $filename == copy-new {
  $char: "\E017";
}
  @if $filename == copy {
  $char: "\E018";
}
  @if $filename == corner {
  $char: "\E019";
}
  @if $filename == crypto-spin {
  $char: "\E01A";
}
  @if $filename == cycle {
  $char: "\E01B";
}
  @if $filename == deposit {
  $char: "\E01C";
}
  @if $filename == doc {
  $char: "\E01D";
}
  @if $filename == dog {
  $char: "\E01E";
}
  @if $filename == download {
  $char: "\E01F";
}
  @if $filename == edit {
  $char: "\E020";
}
  @if $filename == email {
  $char: "\E021";
}
  @if $filename == error {
  $char: "\E022";
}
  @if $filename == eth {
  $char: "\E023";
}
  @if $filename == exchange {
  $char: "\E024";
}
  @if $filename == expand {
  $char: "\E025";
}
  @if $filename == favourite-filled {
  $char: "\E026";
}
  @if $filename == favourite {
  $char: "\E027";
}
  @if $filename == few-games {
  $char: "\E028";
}
  @if $filename == filter {
  $char: "\E029";
}
  @if $filename == full {
  $char: "\E02A";
}
  @if $filename == ghost {
  $char: "\E02B";
}
  @if $filename == gift-new {
  $char: "\E02C";
}
  @if $filename == gift {
  $char: "\E02D";
}
  @if $filename == heart {
  $char: "\E02E";
}
  @if $filename == info {
  $char: "\E02F";
}
  @if $filename == informer {
  $char: "\E030";
}
  @if $filename == ios {
  $char: "\E031";
}
  @if $filename == jpg {
  $char: "\E032";
}
  @if $filename == like {
  $char: "\E033";
}
  @if $filename == like_filled {
  $char: "\E034";
}
  @if $filename == lock {
  $char: "\E035";
}
  @if $filename == long-arrow-left {
  $char: "\E036";
}
  @if $filename == long-arrow-right {
  $char: "\E037";
}
  @if $filename == long-arrow {
  $char: "\E038";
}
  @if $filename == lootbox {
  $char: "\E039";
}
  @if $filename == ltc {
  $char: "\E03A";
}
  @if $filename == missions {
  $char: "\E03B";
}
  @if $filename == more {
  $char: "\E03C";
}
  @if $filename == neo {
  $char: "\E03D";
}
  @if $filename == notify {
  $char: "\E03E";
}
  @if $filename == one-game {
  $char: "\E03F";
}
  @if $filename == password {
  $char: "\E040";
}
  @if $filename == pdf {
  $char: "\E041";
}
  @if $filename == picture-in-picture {
  $char: "\E042";
}
  @if $filename == play {
  $char: "\E043";
}
  @if $filename == plus-bold {
  $char: "\E044";
}
  @if $filename == plus-secondary {
  $char: "\E045";
}
  @if $filename == plus {
  $char: "\E046";
}
  @if $filename == question {
  $char: "\E047";
}
  @if $filename == reroll {
  $char: "\E048";
}
  @if $filename == santa {
  $char: "\E049";
}
  @if $filename == search {
  $char: "\E04A";
}
  @if $filename == shop {
  $char: "\E04B";
}
  @if $filename == side-jackpot {
  $char: "\E04C";
}
  @if $filename == side-tropical {
  $char: "\E04D";
}
  @if $filename == signin {
  $char: "\E04E";
}
  @if $filename == signup {
  $char: "\E04F";
}
  @if $filename == spinfinity {
  $char: "\E050";
}
  @if $filename == support {
  $char: "\E051";
}
  @if $filename == swipe-pointer {
  $char: "\E052";
}
  @if $filename == telegram {
  $char: "\E053";
}
  @if $filename == timer {
  $char: "\E054";
}
  @if $filename == tournaments {
  $char: "\E055";
}
  @if $filename == trash {
  $char: "\E056";
}
  @if $filename == tropical {
  $char: "\E057";
}
  @if $filename == two-coins {
  $char: "\E058";
}
  @if $filename == usdt {
  $char: "\E059";
}
  @if $filename == user {
  $char: "\E05A";
}
  @if $filename == wallet {
  $char: "\E05B";
}
  @if $filename == windows {
  $char: "\E05C";
}
  @if $filename == xmas {
  $char: "\E05D";
}
  @if $filename == xrp {
  $char: "\E05E";
}

@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
&:#{$insert} {
  @if $extend {
    @extend %icon;
  } @else {
    @include icon-styles;
  }
  content: icon-char($filename);
}
}

.icon-ada {
  @include icon(ada);
}
.icon-android {
  @include icon(android);
}
.icon-app-arrow-1 {
  @include icon(app-arrow-1);
}
.icon-app-arrow-2 {
  @include icon(app-arrow-2);
}
.icon-app-arrow-3 {
  @include icon(app-arrow-3);
}
.icon-app {
  @include icon(app);
}
.icon-apple {
  @include icon(apple);
}
.icon-apply-fat {
  @include icon(apply-fat);
}
.icon-apply-wide {
  @include icon(apply-wide);
}
.icon-apply {
  @include icon(apply);
}
.icon-arrow {
  @include icon(arrow);
}
.icon-attention {
  @include icon(attention);
}
.icon-bag-solid {
  @include icon(bag-solid);
}
.icon-bag {
  @include icon(bag);
}
.icon-bch {
  @include icon(bch);
}
.icon-bnb {
  @include icon(bnb);
}
.icon-bonuses {
  @include icon(bonuses);
}
.icon-btc {
  @include icon(btc);
}
.icon-burger {
  @include icon(burger);
}
.icon-car {
  @include icon(car);
}
.icon-close {
  @include icon(close);
}
.icon-compress {
  @include icon(compress);
}
.icon-copy-new {
  @include icon(copy-new);
}
.icon-copy {
  @include icon(copy);
}
.icon-corner {
  @include icon(corner);
}
.icon-crypto-spin {
  @include icon(crypto-spin);
}
.icon-cycle {
  @include icon(cycle);
}
.icon-deposit {
  @include icon(deposit);
}
.icon-doc {
  @include icon(doc);
}
.icon-dog {
  @include icon(dog);
}
.icon-download {
  @include icon(download);
}
.icon-edit {
  @include icon(edit);
}
.icon-email {
  @include icon(email);
}
.icon-error {
  @include icon(error);
}
.icon-eth {
  @include icon(eth);
}
.icon-exchange {
  @include icon(exchange);
}
.icon-expand {
  @include icon(expand);
}
.icon-favourite-filled {
  @include icon(favourite-filled);
}
.icon-favourite {
  @include icon(favourite);
}
.icon-few-games {
  @include icon(few-games);
}
.icon-filter {
  @include icon(filter);
}
.icon-full {
  @include icon(full);
}
.icon-ghost {
  @include icon(ghost);
}
.icon-gift-new {
  @include icon(gift-new);
}
.icon-gift {
  @include icon(gift);
}
.icon-heart {
  @include icon(heart);
}
.icon-info {
  @include icon(info);
}
.icon-informer {
  @include icon(informer);
}
.icon-ios {
  @include icon(ios);
}
.icon-jpg {
  @include icon(jpg);
}
.icon-like {
  @include icon(like);
}
.icon-like_filled {
  @include icon(like_filled);
}
.icon-lock {
  @include icon(lock);
}
.icon-long-arrow-left {
  @include icon(long-arrow-left);
}
.icon-long-arrow-right {
  @include icon(long-arrow-right);
}
.icon-long-arrow {
  @include icon(long-arrow);
}
.icon-lootbox {
  @include icon(lootbox);
}
.icon-ltc {
  @include icon(ltc);
}
.icon-missions {
  @include icon(missions);
}
.icon-more {
  @include icon(more);
}
.icon-neo {
  @include icon(neo);
}
.icon-notify {
  @include icon(notify);
}
.icon-one-game {
  @include icon(one-game);
}
.icon-password {
  @include icon(password);
}
.icon-pdf {
  @include icon(pdf);
}
.icon-picture-in-picture {
  @include icon(picture-in-picture);
}
.icon-play {
  @include icon(play);
}
.icon-plus-bold {
  @include icon(plus-bold);
}
.icon-plus-secondary {
  @include icon(plus-secondary);
}
.icon-plus {
  @include icon(plus);
}
.icon-question {
  @include icon(question);
}
.icon-reroll {
  @include icon(reroll);
}
.icon-santa {
  @include icon(santa);
}
.icon-search {
  @include icon(search);
}
.icon-shop {
  @include icon(shop);
}
.icon-side-jackpot {
  @include icon(side-jackpot);
}
.icon-side-tropical {
  @include icon(side-tropical);
}
.icon-signin {
  @include icon(signin);
}
.icon-signup {
  @include icon(signup);
}
.icon-spinfinity {
  @include icon(spinfinity);
}
.icon-support {
  @include icon(support);
}
.icon-swipe-pointer {
  @include icon(swipe-pointer);
}
.icon-telegram {
  @include icon(telegram);
}
.icon-timer {
  @include icon(timer);
}
.icon-tournaments {
  @include icon(tournaments);
}
.icon-trash {
  @include icon(trash);
}
.icon-tropical {
  @include icon(tropical);
}
.icon-two-coins {
  @include icon(two-coins);
}
.icon-usdt {
  @include icon(usdt);
}
.icon-user {
  @include icon(user);
}
.icon-wallet {
  @include icon(wallet);
}
.icon-windows {
  @include icon(windows);
}
.icon-xmas {
  @include icon(xmas);
}
.icon-xrp {
  @include icon(xrp);
}
