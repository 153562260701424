@use '../variables' as vars;
/**
 * Defines utility classes for positioning elements on the page.
 *
 * The `$position-classes` map defines a set of position values that can be used
 * to set the `position` property of an element. These values include `static`,
 * `relative`, `absolute`, `fixed`, and `sticky`.
 *
 * The utility classes are generated using the `@each` loop, which creates a
 * class for each position value in the `$position-classes` map. For example,
 * the class `.static` sets the `position` property to `static`.
 *
 * The utility classes are also generated for each breakpoint defined in the
 * `$breakpoints` map. These breakpoint-specific classes allow you to set the
 * `position` property of an element at different screen sizes. For example,
 * the class `.md:relative` sets the `position` property to `relative` at the
 * `md` breakpoint.
 */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$position-classes: (
  "static": static,
  "relative": relative,
  "absolute": absolute,
  "fixed": fixed,
  "sticky": sticky
);

@each $key, $value in $position-classes {
  .#{$key} {
    position: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $position-classes {
      .#{$breakpoint}\:#{$key} {
        position: #{$value};
      }
    }
  }
}

