
/**
 * Utility classes for setting the `flex` property on an element.
 *
 * - `.flex-1`: Sets `flex: 1 1 0%`, which makes the element grow and shrink as needed, and sets the initial size to 0%.
 * - `.flex-auto`: Sets `flex: 1 1 auto`, which makes the element grow and shrink as needed, and sets the initial size to the element's content size.
 * - `.flex-initial`: Sets `flex: 0 1 auto`, which makes the element shrink as needed, but does not grow, and sets the initial size to the element's content size.
 * - `.flex-none`: Sets `flex: none`, which disables the element's ability to grow or shrink.
 */
.flex-1 { flex: 1 1 0%; }
.flex-auto { flex: 1 1 auto; }
.flex-initial { flex: 0 1 auto; }
.flex-none { flex: none; }
