@use '../variables' as vars;
/**
 * Utility classes for controlling the alignment of flex container content along the cross axis.
 *
 * The following alignment values are available:
 * - `start`: Aligns the flex container's lines at the start of the cross axis.
 * - `end`: Aligns the flex container's lines at the end of the cross axis.
 * - `center`: Aligns the flex container's lines at the center of the cross axis.
 * - `between`: Distributes the flex container's lines evenly along the cross axis, with the first line at the start and the last line at the end.
 * - `around`: Distributes the flex container's lines evenly along the cross axis, with equal space around each line.
 * - `stretch`: Stretches the flex container's lines to take up the remaining space along the cross axis.
 *
 * These classes can be applied to the flex container at different breakpoints using the responsive prefixes (e.g. `md:content-center`).
 */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$align-content-classes: (
  "start": flex-start,
  "end": flex-end,
  "center": center,
  "between": space-between,
  "around": space-around,
  "stretch": stretch
);

@each $name, $value in $align-content-classes {
  .content-#{$name} {
    align-content: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $name, $value in $align-content-classes {
      .#{$breakpoint}\:content-#{$name} {
        align-content: #{$value};
      }
    }
  }
}

