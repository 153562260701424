
/**
 * Utility classes for controlling the flex-wrap property of flex containers.
 *
 * - `.flex-nowrap`: Sets the `flex-wrap` property to `nowrap`, which means the flex items will all try to fit onto one line.
 * - `.flex-wrap`: Sets the `flex-wrap` property to `wrap`, which means the flex items will wrap onto multiple lines, from top to bottom.
 * - `.flex-wrap-reverse`: Sets the `flex-wrap` property to `wrap-reverse`, which means the flex items will wrap onto multiple lines, from bottom to top.
 */
.flex-nowrap { flex-wrap: nowrap; }
.flex-wrap { flex-wrap: wrap; }
.flex-wrap-reverse { flex-wrap: wrap-reverse; }
