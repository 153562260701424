
/**
 * CSS utility classes for setting the cursor style.
 *
 * - `.cursor-auto`: Sets the cursor to the default system cursor.
 * - `.cursor-default`: Sets the cursor to the default system cursor.
 * - `.cursor-pointer`: Sets the cursor to a pointer (hand) cursor, indicating an interactive element.
 * - `.cursor-wait`: Sets the cursor to a wait cursor, indicating a long-running operation.
 * - `.cursor-text`: Sets the cursor to a text cursor, indicating the ability to select or edit text.
 * - `.cursor-move`: Sets the cursor to a move cursor, indicating the ability to move an element.
 * - `.cursor-not-allowed`: Sets the cursor to a not-allowed cursor, indicating an action that is not permitted.
 */
.cursor-auto { cursor: auto; }
.cursor-default { cursor: default; }
.cursor-pointer { cursor: pointer; }
.cursor-wait { cursor: wait; }
.cursor-text { cursor: text; }
.cursor-move { cursor: move; }
.cursor-not-allowed { cursor: not-allowed; }
