@use '../variables' as vars;
/**
 * Utility classes for setting the flex-direction property on elements.
 *
 * The following flex-direction classes are available:
 * - `.flex-row`: Sets the flex-direction to row.
 * - `.flex-row-reverse`: Sets the flex-direction to row-reverse.
 * - `.flex-col`: Sets the flex-direction to column.
 * - `.flex-col-reverse`: Sets the flex-direction to column-reverse.
 *
 * These classes can also be used with breakpoint modifiers, e.g. `.xs:flex-row` or `.md:flex-col-reverse`.
 */
 $breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$flex-direction-classes: (
  "row": row,
  "row-reverse": row-reverse,
  "col": column,
  "col-reverse": column-reverse
);

@each $name, $value in $flex-direction-classes {
  .flex-#{$name} {
    flex-direction: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $name, $value in $flex-direction-classes {
      .#{$breakpoint}\:flex-#{$name} {
        flex-direction: #{$value};
      }
    }
  }
}
