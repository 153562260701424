@use '../variables' as vars;
/**
 * Utility classes for controlling the overflow behavior of elements.
 *
 * The following overflow classes are available:
 * - `overflow-auto`: Sets `overflow` to `auto`.
 * - `overflow-hidden`: Sets `overflow` to `hidden`.
 * - `overflow-visible`: Sets `overflow` to `visible`.
 * - `overflow-scroll`: Sets `overflow` to `scroll`.
 * - `overflow-clip`: Sets `overflow` to `clip`.
 *
 * The following overflow-x and overflow-y classes are also available:
 * - `overflow-x-auto`, `overflow-y-auto`
 * - `overflow-x-hidden`, `overflow-y-hidden`
 * - `overflow-x-visible`, `overflow-y-visible`
 * - `overflow-x-scroll`, `overflow-y-scroll`
 * - `overflow-x-clip`, `overflow-y-clip`
 *
 * These classes can be used with responsive breakpoints, e.g. `sm:overflow-hidden`.
 */
 $breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$overflow-classes: (
  "auto": auto,
  "hidden": hidden,
  "visible": visible,
  "scroll": scroll,
  "clip": clip
);

@each $key, $value in $overflow-classes {
  .overflow-#{$key} {
    overflow: #{$value};
  }
  .overflow-x-#{$key} {
    overflow-x: #{$value};
  }
  .overflow-y-#{$key} {
    overflow-y: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $key, $value in $overflow-classes {
      .#{$breakpoint}\:overflow-#{$key} {
        overflow: #{$value};
      }
      .#{$breakpoint}\:overflow-x-#{$key} {
        overflow-x: #{$value};
      }
      .#{$breakpoint}\:overflow-y-#{$key} {
        overflow-y: #{$value};
      }
    }
  }
}
