@use '../variables' as vars;

/**
 * Defines a set of font size utility classes for different screen sizes.
 *
 * The `$breakpoints` map defines the screen size breakpoints, and the `$font-sizes` array
 * defines the available font sizes in pixels.
 *
 * The generated utility classes follow the format `.text-{size}` and `.{breakpoint}:text-{size}`,
 * where `{size}` is the font size in pixels and `{breakpoint}` is the screen size breakpoint.
 *
 * For example, the class `.text-16` sets the font size to 16 pixels, and the class
 * `.sm:text-16` sets the font size to 16 pixels on small screens and above.
 */
$breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$font-sizes: 9, 10, 12, 13, 14, 16, 18, 20, 21, 23, 24, 26, 28, 30, 32, 34, 36, 42, 48, 56, 64;

@function px-to-rem($px) {
  @return $px / vars.$base-font-size * 1rem;
}

@each $size in $font-sizes {
  .text-#{$size} {
    font-size: px-to-rem($size);
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $size in $font-sizes {
      .#{$breakpoint}\:text-#{$size} {
        font-size: px-to-rem($size);
      }
    }
  }
}
