@use '../variables' as vars;
/**
 * Utility classes for setting border-radius.
 *
 * - `.rounded-none`: Sets the border-radius to 0.
 * - `.rounded-sm`: Sets the border-radius to 0.125rem.
 * - `.rounded`: Sets the border-radius to 0.25rem.
 * - `.rounded-md`: Sets the border-radius to 0.375rem.
 * - `.rounded-lg`: Sets the border-radius to 0.5rem.
 * - `.rounded-full`: Sets the border-radius to 9999px, creating a circular shape.
 */
.rounded-none { border-radius: 0; }
.rounded-sm { border-radius: vars.$border-radius-sm; }
.rounded { border-radius: vars.$border-radius-base; }
.rounded-md { border-radius: vars.$border-radius-md; }
.rounded-lg { border-radius: vars.$border-radius-lg; }
.rounded-full { border-radius: 9999px; }
