
/**
 * Utility classes for applying translation transforms to elements.
 * The `translate-x-*` classes apply a horizontal translation transform,
 * and the `translate-y-*` classes apply a vertical translation transform.
 * The number in the class name represents the amount of translation in
 * terms of the base font size (1rem).
 */
 $translate-classes: (
  "translate-x-0": translateX(0%),
  "translate-x-50": translateX(50%),
  "translate-x-100": translateX(100%),
  "translate-x-1000": translateX(1000%),
  "translate-y-0": translateY(0%),
  "translate-y-50": translateY(50%),
  "translate-y-100": translateY(100%),
  "translate-y-1000": translateY(1000%)
);
