
/**
 * Defines a set of CSS classes for applying different font weights.
 *
 * The `$font-weights` map defines the available font weight values, where the
 * key is the class name suffix (e.g. `font-weight-300`) and the value is the
 * corresponding CSS `font-weight` value.
 *
 * These classes can be used to easily apply different font weights to elements
 * in your HTML, for example:
 *
 *
 * <p class="font-weight-300">This text has a font weight of 300.</p>
 * <p class="font-weight-700">This text has a font weight of 700.</p>
 *
 */
$font-weights: (
  100: 100,
  200: 200,
  300: 300,
  400: 400,
  500: 500,
  600: 600,
  700: 700,
  800: 800,
  900: 900
);

@each $name, $weight in $font-weights {
  .font-weight-#{$name} {
    font-weight: #{$weight};
  }
}
