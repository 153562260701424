
/**
 * Removes the right margin from the last child element in a container.
 *
 * This utility class can be used to ensure that the last element in a container
 * does not have any right margin, which can help with layout and alignment.
 */
.no-margin-right-last > *:last-child {
  margin-right: 0 !important;
}

/**
 * Removes the bottom margin from the last child element in a container.
 *
 * This utility class can be used to ensure that the last element in a container
 * does not have any bottom margin, which can help with layout and alignment.
 */
.no-margin-bottom-last > *:last-child {
  margin-bottom: 0 !important;
}

/**
 * Removes the left margin from the first child element in a container.
 *
 * This utility class can be used to ensure that the first element in a container
 * does not have any left margin, which can help with layout and alignment.
 */
.no-margin-left-first > *:first-child {
  margin-left: 0 !important;
}

/**
 * Removes the top margin from the first child element in a container.
 *
 * This utility class can be used to ensure that the first element in a container
 * does not have any top margin, which can help with layout and alignment.
 */
.no-margin-top-first > *:first-child {
  margin-top: 0 !important;
}

