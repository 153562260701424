@use '../variables' as vars;
/**
 * Utility classes for setting the `align-items` property on flex containers.
 *
 * The following classes are available:
 * - `.items-start`: Aligns flex items to the start of the cross axis.
 * - `.items-end`: Aligns flex items to the end of the cross axis.
 * - `.items-center`: Aligns flex items to the center of the cross axis.
 * - `.items-baseline`: Aligns flex items to the baseline of the cross axis.
 * - `.items-stretch`: Stretches flex items to fill the cross axis.
 *
 * These classes can also be used with breakpoint modifiers, e.g. `.xs:items-center`.
 */
 $breakpoints: (
  "xl": vars.$bp-xl,
  "ld": vars.$bp-ld,
  "md": vars.$bp-md,
  "sm": vars.$bp-sm,
  "xs": vars.$bp-xs,
);

$align-items-classes: (
  "start": flex-start,
  "end": flex-end,
  "center": center,
  "baseline": baseline,
  "stretch": stretch
);

@each $name, $value in $align-items-classes {
  .items-#{$name} {
    align-items: #{$value};
  }
}

@each $breakpoint, $width in $breakpoints {
  @media (max-width: ($width - 1px)) {
    @each $name, $value in $align-items-classes {
      .#{$breakpoint}\:items-#{$name} {
        align-items: #{$value};
      }
    }
  }
}
