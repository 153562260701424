
/**
 * Utility classes for applying text decoration styles.
 *
 * - `.text-underline`: Applies an underline text decoration.
 * - `.text-line-through`: Applies a line-through text decoration.
 * - `.text-no-underline`: Removes any text decoration.
 */
.text-underline { text-decoration: underline; }
.text-line-through { text-decoration: line-through; }
.text-no-underline { text-decoration: none; }
